import React from 'react';
import { DH } from '../../../utils/DataHook';
import { TextButton } from '../../TextButton';
import { Text } from '../../Text';
import s from './styles.scss';

export const NoWrapInline: React.FC = ({ children }) => <Text className={s.noWrap}>{children}</Text>;

export const CtaModalTrigger = ({ ctaText, onCtaTextClick }: { ctaText: string; onCtaTextClick(): void }) => (
  <TextButton data-hook={DH.PaymentMethodView.ModalTrigger} customElClassName={s.ctaLink} onClick={onCtaTextClick}>
    {ctaText}
  </TextButton>
);

export const Container: React.FC = ({ children }) => (
  <span className={s.noWrap} data-hook={DH.PaymentMethodItem.Generic}>
    {children}
  </span>
);
