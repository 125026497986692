import React from 'react';
import { LaybuyMethod } from '../methods/Laybuy';
import { AffirmMethod } from '../methods/Affirm';
import { CreditCardMethod } from '../methods/CreditCard';
import { SezzleMethod } from '../methods/Sezzle';
import { AtomeMethod } from '../methods/Atome';
import { GenericMethod } from '../methods/Generic';
import { ClientBnplMethodInfo } from '../../types/ClientBnplMethodInfo';
import { InstallmentPaymentMethod } from '../../types/InstallmentPaymentMethod';

export interface PaymentMethodItemProps {
  pmInfo: ClientBnplMethodInfo;
}

const PMComponents: Record<InstallmentPaymentMethod, React.FC<PaymentMethodItemProps>> = {
  [InstallmentPaymentMethod.Affirm]: AffirmMethod,
  [InstallmentPaymentMethod.Laybuy]: LaybuyMethod,
  [InstallmentPaymentMethod.Sezzle]: SezzleMethod,
  [InstallmentPaymentMethod.Atome]: AtomeMethod,
  [InstallmentPaymentMethod.CreditCard]: CreditCardMethod,
};

export const PaymentMethodItem = (props: PaymentMethodItemProps) => {
  const Method = PMComponents[props.pmInfo.paymentMethod] || GenericMethod;

  return <Method pmInfo={props.pmInfo} />;
};
