import React, { useCallback, useMemo } from 'react';
import { installmentBannerClickedMoreInfo } from '@wix/bi-logger-cashier-ugc/v2';
import { getModalSize } from '../../../utils/modalSize';
import { useBiLogger } from '../../../utils/biLogger/useBiLogger';
import { ClientBnplMethodInfo } from '../../../types/ClientBnplMethodInfo';
import { useTopProps } from '../../../context/TopProps';
import { splitTextIntoTwoParts } from './utils';
import {
  Container,
  CtaModalTrigger,
  LogoInline,
  MessageElementInline,
  NoWrapInline,
  ProviderAnalytics,
} from './elements';

export interface GenericMethodProps {
  pmInfo: ClientBnplMethodInfo;
}

export const GenericMethod = ({ pmInfo }: GenericMethodProps) => {
  const { paymentMethod, bnplModalPage } = pmInfo;
  const biLogger = useBiLogger();
  const { theme, deviceType, openModal } = useTopProps();
  const sizeFromServer = bnplModalPage?.resolution;
  const modalUrl = bnplModalPage?.url;
  const logoSrc = theme === 'light' ? pmInfo?.logo.logoLight : pmInfo?.logo.logoDark;
  const modalSize = getModalSize(deviceType, paymentMethod, sizeFromServer);

  const onCtaTextClick = useCallback(() => {
    biLogger?.report(installmentBannerClickedMoreInfo({ paymentProvider: paymentMethod as string }));

    if (openModal && modalUrl) {
      openModal(modalUrl, modalSize.width, modalSize.height, () => {});
    }
  }, [openModal, modalUrl, modalSize, biLogger, paymentMethod]);

  // We don't want the logo of the payment method to fall back on the next line without a context
  // Therefore we'll ensure it will be following with the last two word of the bnpl message (e.g. of $100.00).
  const { first, last } = useMemo(() => splitTextIntoTwoParts(pmInfo.bnplMessage), [pmInfo.bnplMessage]);

  return (
    <Container>
      <MessageElementInline>{first}</MessageElementInline>
      <NoWrapInline>
        <MessageElementInline>{last}</MessageElementInline>
        <LogoInline src={logoSrc} />
        {openModal && bnplModalPage?.ctaText && (
          <CtaModalTrigger ctaText={bnplModalPage?.ctaText} onCtaTextClick={onCtaTextClick} />
        )}
      </NoWrapInline>
      <ProviderAnalytics url={pmInfo?.providerImpressionUrl} />
    </Container>
  );
};
