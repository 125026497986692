import React, { useCallback } from 'react';
import { installmentBannerClickedMoreInfo } from '@wix/bi-logger-cashier-ugc/v2';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { isInModal } from '../../../utils/isInModal';
import { ModalSize } from '../../../utils/modalSize';
import { useBiLogger } from '../../../utils/biLogger/useBiLogger';
import { Text } from '../../Text';
import { DH } from '../../../utils/DataHook';
import { useTopProps } from '../../../context/TopProps';
import { Container, LogoWrap, NoWrapContainer } from './elements';
import s from './styles.scss';
import { DetailsIcon } from './DetailsIcon';

export interface GenericOldMethodProps {
  dataHook: string;
  paymentMethod: PaymentMethod;
  logo?: React.ReactNode;
  mainTitle: string;
  detailsPageUrl?: string;
  modalSize?: {
    mobile: ModalSize;
    desktop: ModalSize;
  };
  priceTitle?: string;
  onDetailsClick?(): void;
}

export const GenericOldMethod = ({
  dataHook,
  paymentMethod,
  logo,
  mainTitle,
  detailsPageUrl,
  modalSize,
  priceTitle,
  onDetailsClick,
}: GenericOldMethodProps) => {
  const biLogger = useBiLogger();
  const { deviceType, openModal } = useTopProps();
  const hideDetailsButton = isInModal() || !detailsPageUrl || (onDetailsClick === undefined && openModal === undefined);
  const deviceModalSize = modalSize?.[deviceType];

  const handleDetailsButtonClick = useCallback(() => {
    biLogger?.report(installmentBannerClickedMoreInfo({ paymentProvider: paymentMethod }));

    if (typeof onDetailsClick === 'function') {
      onDetailsClick();
      return;
    }

    openModal?.(detailsPageUrl, deviceModalSize?.width, deviceModalSize?.height);
  }, [biLogger, openModal, onDetailsClick, detailsPageUrl, deviceModalSize]);

  return (
    <Container dataHook={dataHook}>
      <NoWrapContainer>
        <Text>{mainTitle}</Text>
      </NoWrapContainer>
      <NoWrapContainer>
        {priceTitle && <Text>{priceTitle}</Text>}
        {logo && <LogoWrap>{logo}</LogoWrap>}
        {!hideDetailsButton && (
          <button data-hook={DH.PaymentMethodView.Details} className={s.infoButton} onClick={handleDetailsButtonClick}>
            <DetailsIcon />
          </button>
        )}
      </NoWrapContainer>
    </Container>
  );
};
