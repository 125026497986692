import React from 'react';
import { filterDefined } from '@wix/cashier-common/dist/src/utils/filterDefined';
import { useWiredComponents } from '../../context/TopProps';
import { WiredImageProps } from './types';

export const Image = (props: WiredImageProps) => {
  const WiredComponents = useWiredComponents();

  if (WiredComponents?.Image) {
    return <WiredComponents.Image {...props} />;
  }

  const otherProps = filterDefined({
    alt: props.alt,
    className: props.className,
    'data-hook': props['data-hook'],
  });

  return <img {...otherProps} src={props.src} />;
};
