import React from 'react';
import { DH } from '../../../utils/DataHook';
import { Text } from '../../Text';
import { TextButton } from '../../TextButton';
import { Image } from '../../Image';
import s from './styles.scss';

export const Container: React.FC = ({ children }) => (
  <div data-hook={DH.PaymentMethodItem.Generic} className={s.container}>
    {children}
  </div>
);

export const LogoInline = ({ src }: { src: string }) => (
  <span data-hook={DH.PaymentMethodView.Logo} className={s.logo}>
    <Image src={src} alt="Payment method logo" />
  </span>
);

export const NoWrapInline: React.FC = ({ children }) => <span className={s.noWrap}>{children}</span>;

export const MessageElementInline: React.FC = ({ children }) => <Text className={s.messageElement}>{children}</Text>;

export const CtaModalTrigger = ({ ctaText, onCtaTextClick }: { ctaText: string; onCtaTextClick(): void }) => (
  <TextButton data-hook={DH.PaymentMethodView.ModalTrigger} customElClassName={s.ctaLink} onClick={onCtaTextClick}>
    {ctaText}
  </TextButton>
);

export const ProviderAnalytics = ({ url }: { url?: string }) =>
  url ? <img src={url} className={s.hidden} alt="" /> : null;
