import React from 'react';
import { installmentBannerClickedMoreInfo } from '@wix/bi-logger-cashier-ugc/v2';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { DH } from '../../../utils/DataHook';
import { isInModal } from '../../../utils/isInModal';
import { getDetailsPageUrl } from '../../../utils/detailsPage';
import { ClientBnplMethodInfo } from '../../../types/ClientBnplMethodInfo';
import { useBiLogger } from '../../../utils/biLogger/useBiLogger';
import { useTopProps } from '../../../context/TopProps';
import { getAffirmReactComponents } from './getAffirmReactComponents';
import s from './styles.scss';
import { AFFIRM_MODAL_SIZE } from './constants';

export interface AffirmMethodProps {
  pmInfo: ClientBnplMethodInfo;
}

export const AffirmMethod = ({ pmInfo }: AffirmMethodProps) => {
  const biLogger = useBiLogger();
  const { theme = 'light', deviceType = 'desktop', staticsBaseUrl, openModal } = useTopProps();
  const logoSrc = theme === 'light' ? pmInfo?.logo.logoLight : pmInfo?.logo.logoDark;
  const hideModalTrigger = isInModal() || openModal === undefined;
  const detailsPageUrl = getDetailsPageUrl({
    staticsBaseUrl,
    contentUrl: encodeURIComponent(pmInfo.infoUrl),
    deviceType,
  });

  const AffirmElements = getAffirmReactComponents(pmInfo.promoMessage, {
    logoSrc,
    deviceType,
    onModalTriggerClick() {
      biLogger.report(installmentBannerClickedMoreInfo({ paymentProvider: PaymentMethod.Affirm }));
      openModal(detailsPageUrl, AFFIRM_MODAL_SIZE[deviceType].width, AFFIRM_MODAL_SIZE[deviceType].height, () => {});
    },
    hideModalTrigger,
  });

  return (
    <div data-hook={DH.PaymentMethodItem.Affirm} className={s.container}>
      {AffirmElements.map((AffirmElement, i) => (
        <AffirmElement key={i} />
      ))}
    </div>
  );
};
