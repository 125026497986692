import { DesktopModalSize, MobileModalSize, ModalSize } from '../../../../utils/modalSize';
import { InstallmentPaymentMethod } from '../../../../types/InstallmentPaymentMethod';
import { DeviceType } from '../../../../types/DeviceType';

// TODO: consider getting from metadata
export const SEZZLE_INSTALLMENTS_NUMBER = 4;

export const SEZZLE_MODAL_SIZE: Record<DeviceType, ModalSize> = {
  desktop: DesktopModalSize[InstallmentPaymentMethod.Sezzle],
  mobile: MobileModalSize,
};
