import { DeviceType } from '../types/DeviceType';

export const DETAILS_PATH = 'assets/pages/details-page.html';

export const getDetailsPageUrl = ({
  staticsBaseUrl = '',
  contentUrl,
  deviceType = 'desktop',
}: {
  staticsBaseUrl?: string;
  contentUrl: string;
  deviceType?: DeviceType;
}): string => {
  return `${staticsBaseUrl}${
    staticsBaseUrl.endsWith('/') ? '' : '/'
  }${DETAILS_PATH}?contentUrl=${contentUrl}&deviceType=${deviceType}`;
};
