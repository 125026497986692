import { FedopsLogger } from '@wix/fedops-logger';
import { PaymentMethodService } from '@wix/cashier-core/dist/src/services/PaymentMethod';
import { BuyNowPayLaterMethod } from '@wix/cashier-core/dist/src/services/PaymentMethod/types';
import { InteractionName } from '../fedopsLogger/InteractionName';
import { CurrencyString } from '../../types/Currency';
import { AuthParams } from '../../types/AuthParams';
import { buildAccountId } from './utils';

interface FetchBnplMethodsParams extends AuthParams {
  appDefId: string;
  appInstanceId: string;
  baseURL?: string;
  amount: string;
  currency: CurrencyString;
  language: string;
  fedopsLogger: FedopsLogger;
}

export const fetchBnplMethods = async (params: FetchBnplMethodsParams): Promise<BuyNowPayLaterMethod[]> => {
  const {
    appDefId,
    appInstanceId,
    appInstance,
    baseURL,
    amount,
    currency,
    language,
    fedopsLogger,
    revalidateAppInstance,
  } = params;

  fedopsLogger.interactionStarted(InteractionName.FetchBnplMethods);
  const paymentMethodsService = new PaymentMethodService({
    baseURL,
    appInstance,
    revalidateAppInstance,
    enableRetry: false,
  });
  const { paymentMethods } = await paymentMethodsService.fetchBuyNowPayLaterMethods(
    buildAccountId({ appDefId, appInstanceId }),
    {
      amount,
      language,
      currency,
    },
  );
  fedopsLogger.interactionEnded(InteractionName.FetchBnplMethods);

  return paymentMethods;
};
