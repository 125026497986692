import { experimentsService } from '@wix/cashier-common/dist/src/utils/experimentsService';
import React from 'react';
import debounce from 'lodash.debounce';
import { PaymentMethodsBannerInternalProps } from '../../types/PaymentMethodsBannerProps';
import { InteractionName } from '../../utils/fedopsLogger/InteractionName';
import { getInitExperimentsParams } from '../../utils/getInitExperimentsParams';
import { initI18n } from '../../utils/i18n';
import { reportError } from '../../utils/Sentry';
import { fetchBnplMethods } from '../../utils/serverAPI/fetchBnplMethods';
import { InstallmentPaymentMethod } from '../../types/InstallmentPaymentMethod';
import { ClientBnplMethodInfo } from '../../types/ClientBnplMethodInfo';
import { PaymentMethodsBannerFC } from './component';

const DEBOUNCE_TIME = 200;

export interface PaymentMethodsBannerState {
  isLoading: boolean;
  isError: boolean;
  bnplMethods: ClientBnplMethodInfo[];
}

// TODO: move all logic to /component
export class PaymentMethodsBanner extends React.Component<
  PaymentMethodsBannerInternalProps,
  PaymentMethodsBannerState
> {
  static defaultProps: Partial<PaymentMethodsBannerInternalProps> = {
    theme: 'light',
    deviceType: 'desktop',
  };

  state: PaymentMethodsBannerState = {
    isLoading: true,
    isError: false,
    bnplMethods: [],
  };

  private readonly initI18nPromise: Promise<void>;
  private readonly initExperimentsPromise: Promise<void>;
  private _isMounted = true;
  private fullLoadDependencies: Promise<void>[] = [];

  constructor(props: PaymentMethodsBannerInternalProps) {
    super(props);

    props.fedopsLogger.interactionEnded(InteractionName.BannerComponentLoad);
    props.fedopsLogger.interactionStarted(InteractionName.BannerRender);

    this.initI18nPromise = this.initI18next(props.locale);
    this.addFullLoadDependency(this.initI18nPromise);

    this.initExperimentsPromise = this.initExperiments();
    this.addFullLoadDependency(this.initExperimentsPromise);
  }

  componentDidMount() {
    this.addFullLoadDependency(this.fetchBnplMethodsAndSetToState());
    this.initLoadListener();
  }

  async componentDidUpdate(prevProps: PaymentMethodsBannerInternalProps): Promise<void> {
    if (prevProps.amount !== this.props.amount) {
      try {
        // This is commented out because for some reason we don't want to hide the banner
        // when the amount changes (thus showing the old view until new BNPL methods are fetched).
        // this.setStateSafe({ isLoading: true, isError: false });
        await this.fetchBnplMethodsAndSetToStateDebounced();
        this.handleFullLoad();
      } catch (e) {
        this.handleFullLoad(e);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  private fetchBnplMethodsAndSetToStateDebounced = debounce(this.fetchBnplMethodsAndSetToState, DEBOUNCE_TIME, {
    leading: true,
  });

  private async fetchBnplMethodsAndSetToState(): Promise<void> {
    const {
      amount,
      fedopsLogger,
      locale,
      currency,
      meta: { appDefId, appInstanceId },
    } = this.props;

    const bnplMethods = await fetchBnplMethods({
      appDefId,
      appInstanceId,
      amount,
      currency,
      fedopsLogger,
      language: locale,
      baseURL: '/', // TODO: should be props.meta.host || '/'?
      appInstance: this.props.meta.appInstance,
      revalidateAppInstance: this.props.revalidateAppInstance,
    });

    this.setStateSafe({
      bnplMethods,
    });
  }

  initExperiments = async () => {
    await experimentsService.init(getInitExperimentsParams(this.props));
  };

  initI18next = (locale: string) => {
    return initI18n(locale || 'en');
  };

  addFullLoadDependency = (dependency: Promise<any>) => {
    this.fullLoadDependencies.push(dependency);
  };

  handleFullLoad = (error?: Error) => {
    this.setStateSafe({ isLoading: false });

    if (error) {
      this.setStateSafe({ isError: true });
      reportError(error);
    } else {
      this.props.onFullLoad?.({
        paymentMethods: this.state.bnplMethods.map(
          ({ paymentMethod }) => paymentMethod as unknown as InstallmentPaymentMethod,
        ),
      });

      if (!this.state.bnplMethods.length) {
        this.props.onEmpty?.();
      }
    }
  };

  initLoadListener = () => {
    Promise.all(this.fullLoadDependencies)
      .then(() => {
        this.handleFullLoad();
        this.reportBannerFullLoad();
      })
      .catch(this.handleFullLoad);
  };

  private setStateSafe(state: Partial<PaymentMethodsBannerState>, callback?: () => void) {
    if (this._isMounted) {
      this.setState(state as PaymentMethodsBannerState, callback);
    }
  }

  reportBannerFullLoad = () => {
    this.props.fedopsLogger.interactionEnded(InteractionName.BannerRender);
  };

  render() {
    const { isLoading, isError, bnplMethods } = this.state;

    if (isLoading || isError) {
      return null;
    }

    return <PaymentMethodsBannerFC {...this.props} bnplMethods={bnplMethods} />;
  }
}
