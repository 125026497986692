import React from 'react';
import { useWiredComponents } from '../../context/TopProps';
import { WiredTextProps } from './types';

export const Text: React.FC<WiredTextProps> = ({ children, ...props }) => {
  const WiredComponents = useWiredComponents();

  if (WiredComponents?.Text) {
    return <WiredComponents.Text {...props}>{children}</WiredComponents.Text>;
  }

  return <span {...props}>{children}</span>;
};
