import React from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { PaymentMethodsComponentLoaderProps } from '../../types/PaymentMethodsBannerProps';
import { initErrorReporter } from '../../utils/Sentry';
import { PaymentMethodsBanner } from './controller';

initErrorReporter();

export const PaymentMethodsBannerRoot = (props: PaymentMethodsComponentLoaderProps) => (
  <ErrorBoundary>
    <PaymentMethodsBanner {...props} />
  </ErrorBoundary>
);
