import React from 'react';
import { installmentBannerListLoaded, installmentBannerLoadFinished } from '@wix/bi-logger-cashier-ugc/v2';
import { experimentsService } from '@wix/cashier-common/dist/src/utils/experimentsService';
import { BiLogger } from '../../utils/biLogger';
import { Experiment } from '../../types/Experiment';
import { ClientBnplMethodInfo } from '../../types/ClientBnplMethodInfo';
import { useBiLogger } from '../../utils/biLogger/useBiLogger';
import { PaymentMethodsListView } from './view.expanded';
import { PaymentMethodsListViewCollapsed } from './view.collapsed';
import { getBiOfferId, getPaymentProvider, sortPaymentMethodsByRank } from './utils';

interface PaymentMethodsListControllerProps {
  bnplMethods: ClientBnplMethodInfo[];
  biLogger?: BiLogger;
}

export interface PaymentMethodsListState {
  sortedBnplMethods: ClientBnplMethodInfo[];
  biOfferId: string;
  paymentProvider: string;
}

const COLLAPSED_VIEW_BREAKPOINT = 3;

class PaymentMethodsListController extends React.PureComponent<
  PaymentMethodsListControllerProps,
  PaymentMethodsListState
> {
  state: PaymentMethodsListState = {
    sortedBnplMethods: [],
    biOfferId: '',
    paymentProvider: '',
  };

  static getDerivedStateFromProps(props: PaymentMethodsListControllerProps) {
    const sortedBnplMethods = sortPaymentMethodsByRank(props.bnplMethods);
    return {
      sortedBnplMethods,
      biOfferId: getBiOfferId(sortedBnplMethods),
      paymentProvider: getPaymentProvider(sortedBnplMethods),
    };
  }

  componentDidMount() {
    this.props.biLogger?.report(
      installmentBannerLoadFinished({
        offerId: this.state.biOfferId,
        paymentProvider: this.state.paymentProvider,
      }),
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.biOfferId !== prevState.biOfferId) {
      this.props.biLogger?.report(
        installmentBannerListLoaded({
          offerId: this.state.biOfferId,
          paymentProvider: this.state.paymentProvider,
        }),
      );
    }
  }

  render() {
    const { sortedBnplMethods } = this.state;

    if (!sortedBnplMethods.length) {
      return null;
    }

    const isCollapsed =
      experimentsService.isEnabled(Experiment.EnableCollapsedView) &&
      sortedBnplMethods.length >= COLLAPSED_VIEW_BREAKPOINT;
    const PaymentMethodsListComponent = isCollapsed ? PaymentMethodsListViewCollapsed : PaymentMethodsListView;

    return <PaymentMethodsListComponent bnplMethods={sortedBnplMethods} />;
  }
}

export const PaymentMethodsList = (props: PaymentMethodsListControllerProps) => {
  const biLogger = useBiLogger();

  return <PaymentMethodsListController biLogger={biLogger} {...props} />;
};
