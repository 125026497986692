import React from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import { prettyNumber } from '../../../utils/amount';
import { ClientBnplMethodInfo } from '../../../types/ClientBnplMethodInfo';
import { GenericOldMethod } from '../GenericOld';
import { DH } from '../../../utils/DataHook';
import { useTopProps } from '../../../context/TopProps';
import { Image } from '../../Image';
import { SEZZLE_MODAL_SIZE } from './utils/constants';

export interface SezzleMethodProps {
  pmInfo: ClientBnplMethodInfo;
}

export const SezzleMethod = ({ pmInfo }: SezzleMethodProps) => {
  const { t } = useTranslation();
  const { currency, theme = 'light' } = useTopProps();
  const {
    infoUrl: detailsPageUrl,
    paymentOptions,
    logo: { logoDark, logoLight },
  } = pmInfo;
  const pOption = paymentOptions[0];

  if (!pOption) {
    return null;
  }

  const mainTitle = t('sezzle.view.mainTitle');
  const priceTitle = t('sezzle.view.priceTitle', {
    installment: prettyNumber(pOption.installmentAmount),
    currency: getSymbolFromCurrency(currency),
  });

  const logoURL = theme === 'dark' ? logoDark : logoLight;

  return (
    <GenericOldMethod
      dataHook={DH.PaymentMethodItem.Sezzle}
      paymentMethod={PaymentMethod.Sezzle}
      logo={<Image src={logoURL} alt="Sezzle logo" />}
      mainTitle={mainTitle}
      detailsPageUrl={detailsPageUrl}
      modalSize={SEZZLE_MODAL_SIZE}
      priceTitle={priceTitle}
    />
  );
};
